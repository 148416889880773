import styled from 'styled-components'

export const StyledLoginPage = styled.div``

export const IFrameVisibilityControl = styled.div<{ isLoading?: boolean }>`
  ${(props) =>
    props.isLoading
      ? `
        height: 0;
        overflow: hidden;
        visibility: hidden;
      `
      : ''}
`
