import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { accountProviderActions } from 'astra-core/containers/AccountProvider'

import {
  EPostMessageIdEvent,
  useGetPostAuthServiceMessage
} from 'astra-core/containers/AuthMessageProvider'

import { LoaderSkeleton } from 'components/LoginPage/components'
import { useGetIframeData } from 'components/LoginPage/hooks'
import { ERoutes } from 'routes/routes.types'

import { IFrameVisibilityControl, StyledLoginPage } from './LoginPage.styled'
import { IIFrame } from './LoginPage.types'

export const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()

  const hideModalWindow = useCallback(() => {
    history.push(ERoutes.Root)
  }, [history])

  const handleAuthIFrameLoad = useCallback(() => {
    setIsLoading(false)
  }, [])

  useGetPostAuthServiceMessage(EPostMessageIdEvent.close, hideModalWindow)

  useEffect(() => {
    /* Reset typeAuthModal */
    return () => {
      dispatch(accountProviderActions.setTypeAuthModal(null))
    }
  }, [dispatch])

  return (
    <StyledLoginPage>
      {isLoading && <LoaderSkeleton />}
      <IFrameVisibilityControl isLoading={isLoading}>
        <IFrame setSuccessfulLoading={handleAuthIFrameLoad} />
      </IFrameVisibilityControl>
    </StyledLoginPage>
  )
}

const IFrame: FC<IIFrame> = ({ setSuccessfulLoading }) => {
  const { id, src, styles } = useGetIframeData()

  return (
    <iframe
      id={id}
      frameBorder="0"
      onLoad={setSuccessfulLoading}
      src={src}
      style={styles}
      title="iframeAuth"
    />
  )
}
