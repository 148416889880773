import qs from 'qs'
import Cookies from 'js-cookie'

import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { getCurrentLng } from 'astra-core/utils'
import { selectTypeAuthModal } from 'astra-core/containers/AccountProvider'
import { EAuthPagesAndModal } from 'astra-core/constants/auth'

import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'
import { useSelector } from 'react-redux'

import { useCallback, useMemo } from 'react'

import { ADVERT_INFO_COOKIE } from 'constants/cookies.constants'
import { STYLE_SUM_HEADER_BOTTOM_MENU_HEIGHT_PX } from 'constants/styles'

export const useGetIframeData = () => {
  const typeAuthModal = useSelector(selectTypeAuthModal)
  const config = useSelector(selectConfig)
  const typeTheme = useSelector(selectUserSettingLayoutTheme)

  const rawParams = {
    'front-state': `mobile${typeTheme ? `-${typeTheme.toLowerCase()}` : ''}`,
    locale: `${getCurrentLng()}`,
    'advert-info': Cookies.get(ADVERT_INFO_COOKIE),
    fastSignUpRedirect: `${config.AUTH_REDIRECT_URL}/fast-sign-up-success`,
    clientRulesUrl: `${config.CLIENT_RULES_URL}`
  }
  const queryParams = qs.stringify(rawParams)

  const shieldedRedirect = useCallback(() => {
    switch (typeAuthModal) {
      case EAuthPagesAndModal.FastSignUp:
        return rawParams.fastSignUpRedirect
      default:
        return null
    }
  }, [rawParams.fastSignUpRedirect, typeAuthModal])

  const shieldedQueryParams = useMemo(
    () =>
      qs.stringify(
        { 'redirect-url': shieldedRedirect() },
        { delimiter: '%26' }
      ),
    [shieldedRedirect]
  )

  const src = useMemo(() => {
    switch (typeAuthModal) {
      case EAuthPagesAndModal.FastSignUp:
        return `${config.AUTH_SERVER_URL}/api/fast-sign-up?${queryParams}&redirect-url=${config.API_URL}/oauth2/authorization/betweb-api-oidc?${shieldedQueryParams}`
      case EAuthPagesAndModal.Recovery:
        return `${config.AUTH_SERVER_URL}/ui/recovery?${queryParams}&redirect-url=${config.API_URL}/oauth2/authorization/betweb-api-oidc?${shieldedQueryParams}`
      default:
      case EAuthPagesAndModal.SignIn:
        return `${config.API_URL}/oauth2/authorization/betweb-api-oidc?${queryParams}`
    }
  }, [
    config.API_URL,
    config.AUTH_SERVER_URL,
    queryParams,
    shieldedQueryParams,
    typeAuthModal
  ])

  return {
    id: 'iframeAuthId',
    src,
    styles: {
      minWidth: '100vw',
      height: `calc(100vh - ${STYLE_SUM_HEADER_BOTTOM_MENU_HEIGHT_PX} - 4px)`
    },
    typeAuthModal
  }
}
