import React from 'react'

import { useTranslation } from 'react-i18next'

import { selectTypeAuthModal } from 'astra-core/containers/AccountProvider'

import { useSelector } from 'react-redux'

import { EAuthPagesAndModal } from 'astra-core/constants/auth'

import { ELoaderSkeletonBlockView } from 'components/LoginPage/components/LoaderSkeleton/LoaderSkeleton.types'

import {
  StyledLoaderSkeleton,
  StyledLoaderSkeletonBlock,
  StyledLoaderSkeletonTitle
} from './LoaderSkeleton.styled'

export const LoaderSkeleton = () => {
  const [t] = useTranslation()
  const typeAuthModalShown = useSelector(selectTypeAuthModal)

  return (
    <StyledLoaderSkeleton>
      <StyledLoaderSkeletonTitle>
        {t(
          typeAuthModalShown === EAuthPagesAndModal.FastSignUp
            ? 'sms registration'
            : 'log in'
        )}
      </StyledLoaderSkeletonTitle>

      <StyledLoaderSkeletonBlock
        view={ELoaderSkeletonBlockView.Text}
        marginBottom={4}
      />

      <StyledLoaderSkeletonBlock
        view={ELoaderSkeletonBlockView.Input}
        marginBottom={16}
      />

      <StyledLoaderSkeletonBlock
        view={ELoaderSkeletonBlockView.Text}
        marginBottom={4}
      />

      <StyledLoaderSkeletonBlock
        view={ELoaderSkeletonBlockView.Input}
        marginBottom={16}
      />

      <StyledLoaderSkeletonBlock
        view={ELoaderSkeletonBlockView.Text}
        marginBottom={8}
      />

      <StyledLoaderSkeletonBlock
        view={ELoaderSkeletonBlockView.Text}
        marginBottom={22}
      />

      <StyledLoaderSkeletonBlock view={ELoaderSkeletonBlockView.Input} />
    </StyledLoaderSkeleton>
  )
}
